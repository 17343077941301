<template>
  <!-- <div>暂未开放</div> -->
  <div class="main">
    <div class="main_left">
      <div v-if="show" :id="'player'" class="player"></div>
    </div>
    <div class="main_right">
      <div class="right_top">目录</div>
      <div class="list">
        <el-scrollbar style="height:100%">
          <el-menu :default-openeds="opens" :collapse="isCollapse">
            <subMenu
              :menu="menu"
              v-for="menu in menuList"
              :key="menu.id"
            ></subMenu>
          </el-menu>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import { getStudy } from "../../../api/class";
import SubMenu from "../components/SubMenu3.vue";
export default {
  name: "course3",
  components: {
    SubMenu,
  },
  data() {
    return {
      show: true,
      vid: "",
      menuList: [],
      player: null,
    };
  },

  created() {
    this.getList();
    this.init();
  },

  methods: {
    getList() {
      getStudy({ type: "3" }).then((res) => {
        if (res.data.msg == "success" && res.data.data.list) {
          this.menuList = res.data.data.list;
          this.getVid(this.menuList);
          this.initPlayer(this.vid);
          this.changeVid(this.menuList, this.vid);
        }
      });
    },
    getVid(menuList) {
      for (let i = 0; i < menuList.length; i++) {
        if (menuList[i].children) {
          this.getVid(menuList[i].children);
          break;
        }
        if (menuList[i].videoCode) {
          this.vid = menuList[i].videoCode;
          break;
        }
        break;
      }
    },
    changeVid(menuList, vid) {
      menuList.map((v) => {
        if (v.children) {
          this.changeVid(v.children, this.vid);
        }
        if (v.videoCode) {
          if (v.videoCode == vid) {
            v.click = true;
          } else {
            v.click = false;
          }
        }
      });
    },
    init() {
      this.$bus.off("videoCode3");
      this.$bus.on("videoCode3", (val) => {
        this.vid = val;
        this.changePlayer(val);
      });
    },
    initPlayer(vid) {
      this.show = false
      let that = this
      setTimeout(() => {
        that.show = true
        that.$nextTick(() => {
        that.player = polyvPlayer({
          wrap: "#player",
          height: "100%",
          vid: vid, //云点播平台的视频唯一id。
        });
      });
      }, 200);     
    },
    changePlayer(vid) {
      this.changeVid(this.menuList, vid);
      this.player.changeVid({
        vid: vid, //需要切换的视频vid
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  width: 98%;
  height: calc(85vh - 80px);
  margin: 0 auto;
  display: flex;
  margin-top: 40px;
}
.main_left {
  width: 75%;
  height: 100%;
}
.player {
  width: 100%;
  height: 100%;
}
.main_right {
  width: 25%;
  height: 100%;
  background-color: #46486a !important;
}
.list {
  height: calc(100% - 45px);
}
.right_top {
  font-size: 20px;
  color: #fff;
  height: 45px;
  padding-left: 21px;
  line-height: 50px;
}
.list :deep(.el-menu) {
  background-color: #46486a;
  color: #fff;
  border: none;
  margin-left: 0;
}
.list :deep(.el-sub-menu__title) {
  height: 33px !important;
  line-height: 33px;
  background-color: #70739d !important;
  color: #fff;
  padding: 0 15px;
  font-size: 14px;
}
.list :deep(.el-menu-item) {
  display: block;
  height: 33px !important;
  line-height: 33px;
  background-color: #46486a !important;
  color: #fff;
  padding: 0 15px !important;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.list :deep(.el-menu-item.is-active) {
  color: #e3700e !important;
}
</style>
