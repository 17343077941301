<template>
  <el-sub-menu
    :index="menu.id"
    v-if="menu.children"
    @click.once="getId(menu.videoCode)"
  >
    <template #title>
      <span>{{ menu.name }}</span>
    </template>
    <sub-menu3
      :menu="menuItem"
      v-for="menuItem in menu.children"
      :key="menuItem.id"
    >
      <span>{{ menuItem.name }}</span>
    </sub-menu3>
  </el-sub-menu>
  <el-menu-item :index="menu.id" v-else @click="getId(menu.videoCode, menu)">
    <template #title>
      <span>&nbsp;</span>
      <span v-if="menu.click">
        <img src="../../../assets/liveimg1.gif" alt="" /> {{ menu.name }}
      </span>
      <span v-else>{{ menu.name }}</span>
    </template>
  </el-menu-item>
</template>

<script>
export default {
  name: "SubMenu3",
  components: {},
  props: {
    menu: Array,
    id: Number,
    name: String,
    chineseName: String,
    childMenu: Array,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    getId(e) {
      if (e == "") {
        return;
      }
      let videoCode = e;
      this.$bus.emit("videoCode3", videoCode);
    },
  },
};
</script>

<style scoped lang="less"></style>
